/** @format */

import moment from 'moment';
import { months, monthsShort, weekdays, weekdaysShort, weekdaysMin } from './frenchLocale.js';
moment.updateLocale('fr', { months: months, monthsShort: monthsShort, weekdays: weekdays, weekdaysShort: weekdaysShort, weekdaysMin: weekdaysMin });

export default class Translator
{
    constructor()
    {

    }

  /**
   * Checks the title and aria label from the given selectors and translate them accoridng to the given translation.
   * @param {*} translations
   * @param {*} selectors
   */
   ariaLabel(translations, selectors)
   {
       // Loop through all the nodes.
       selectors.forEach(
           selector => {
               // Loop through all the results for the current selector.
               document.querySelectorAll(selector).forEach(item => {
                   let title = item.title.trim();

                   let results;
                   // true: Translations were found for the current item's Title.
                   if ((results = translations[title])) {
                       let translation;
                       // true: A translation for the requested language was found.
                       // false: The text for the current item remains unchanged.
                       if ((translation = results[this.to()])) {
                           item.title = translation;
                           item.setAttribute('aria-label', translation);
                       }
                   }
               });
            },
       );

       return this;
   }

   /**
    * Checks the content on translations and translate it selectors using moment.
    * @param {*} translations
    */
    dates(translations)
    {
        for (let selector in translations) {
            let results = translations[selector];
            let formats = results[this.to()];

            document.querySelectorAll(selector).forEach(item => {
                let innerHTML = item.innerText
                                    .replace('Date/Time:', '')
                                    .replace('Date Range:', '')
                                    .trim();

                if (innerHTML.includes(' – ')) {
                    if (this.to() === 'fr') {
                        let dates  = innerHTML.split(' – ');
                        let format = formats[0].split(' – ');
                        let firstDate  = moment(dates[0], format[0], 'en');
                        let secondDate = moment(dates[1], format[1], 'en');

                        if (firstDate.isValid() && secondDate.isValid()) {
                            item.innerHTML = firstDate.locale(this.to()).format(format[0]) + ' – ' + secondDate.locale(this.to()).format(format[1]);
                        }
                    }
                } else {
                    let date = moment(innerHTML, results.en, 'en');

                    if (date.isValid()) {
                        let i = results.en.indexOf(date.creationData().format);
                        item.innerHTML = date.locale(this.to()).format(formats[i]);
                    }
                }
            });
        }

        return this;
    }

   /**
    * Checks the content on translations and sets a MutationObserver for the selectors given.
    * That MutationObserver is there waiting for changes on the node to triger the given callback.
    * @param {*} translations
    */
    deferred(translations)
    {
        for (let selector in translations) {
            let options = translations[selector];
            let nodes = this.selector(selector);

            if (nodes) {
                nodes.forEach(node => {
                    let observer = new MutationObserver(mutationsList => {
                        for (let mutation of mutationsList) {
                            if (options.mutations[mutation.type]) {
                                options.callback(mutation.type, node);
                                observer.disconnect();
                            }
                        }
                    });

                    observer.observe(node, options.mutations);
                });
            }
        }

        return this;
    }

    href(translations, selectors)
    {
        selectors.forEach(
            selector => {
                // Loop through all the nodes.
                document.querySelectorAll(selector).forEach(item => {
                    let title = item.text.trim();
                    let results;
                    if (results = translations[title]) {
                        let translation
                        let href= item.href

                        if (item.href.indexOf(results['en']) != -1) {
                            if ((translation = results[this.to()])) {
                                item.href = translation;
                                item.setAttribute('href', translation);
                            }
                        }
                    }
                });
            },
        );
    }

   /**
    * Checks the content on translations and displays a translation for the events given.
    * @param {*} translations
    */
    onEvent(translations)
    {
        for (let selector in translations) {
            let options = translations[selector];
            let nodes = this.selector(selector);

            if (nodes) {
                nodes.forEach(node => {
                    node.addEventListener(options.on, event => options.callback(node, event));
                });
            }
        }

        return this;
    }

   /**
    * Checks the content on translations and displays the string depending on the language.
    * Requires the following structure: 'en string | other language string'
    * @param {*} translations
    */
    pipe(translations)
    {
        for (let selector in translations) {
            document.querySelectorAll(selector).forEach(item => {
                item.innerHTML = item.innerHTML.replace(
                    /(.+[^|])\s\|\s(.+[^|])/,
                    (match, a, b) => {
                        return this.to() === 'en' ? a : b;
                    },
                );
            });
        }

        return this;
    }

   /**
    * Checks the content on translations and translate it selectors.
    * @param {*} translations
    */
    regex(translations)
    {
        for (let selector in translations) {
            let results = translations[selector];

            document.querySelectorAll(selector).forEach(item => {
                results.forEach(results => {
                    item.innerHTML = item.innerHTML.trim().replace(
                        results.pattern,
                        results.callback,
                    );
                });
            });
        }

        return this;
    }


    /**
     * Checks if a selector contains the matched string and translates it accoridngly.
     * @param {*} translations
     * @param {*} selectors
     */
    replace(translations, selectors)
    {
        // Loop through the list of translations to see if the textContent of the selector has the string in it
        selectors.forEach(
            selector => {
                // Loop through all the nodes.
                document.querySelectorAll(selector).forEach(item => {

                    // Get the content of the selector and trim the whitespace
                    let content = item.innerHTML.trim();

                    // Loop through translations
                    Object.entries(translations).forEach(([key, results]) => {
                        if (content.includes(key)) {
                            let translation;
                            // Get the correcet version (en/fr)
                            if ((translation = results[this.to()])) {
                                content = content.replace(key, translation);
                            }
                        }
                    });

                    item.innerHTML = content;

                });
            },
        );

        return this;
    }

   /**
    * Wrapper for document.querySelectorAll.
    * If there's nodes return them. Otherwise returns false.
    * @param {*} selector
    */
    selector(selector)
    {
        let nodes = document.querySelectorAll(selector);
        return nodes.length > 0 ? nodes : false;
    }

   /**
    * Loops the provided translations and checks the content on selectors to translate the string on those selectors.
    * @param {*} translations
    * @param {*} selectors
    */
    string(translations, selectors)
    {
        // Loop through all the nodes.
        selectors.forEach(
            selector => {
                // Loop through all the results for the current selector.
                document.querySelectorAll(selector).forEach(item => {
                    let html = item.innerHTML.trim();
                    let results;
                    // true: Translations were found for the current item's HTML.
                    if ((results = translations[html])) {
                        let translation;
                        // true: A translation for the requested language was found.
                        // false: The text for the current item remains unchanged.
                        if ((translation = results[this.to()])) {
                            item.innerHTML = translation;
                        }
                    }
                });
             },
        );

        return this;
    }

   /**
    * Returns the language used on window.language.
    */
    to()
    {
        return window.language || 'en';
    }
}

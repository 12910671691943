/** @format */

import Translator from './Translator.js';

// Moment Timezone is used instead of the usual moment for the helpers file.
// This is because the hiding time helper needs to use the Canadian Time zone to work as expected.
import moment from 'moment-timezone';

import { frenchRegexTranslations } from './regexTranslations.js';

import { dateTranslations } from './dateTranslations.js';

const translator = new Translator();

$.fn.extend({
    toggleText: function(a, b){
        return this.text(this.text() == b ? a : b);
    }
});

/**
 * Loop through all of the events.
 * Check the date is greater than the queried date. If it is, remove it from the DOM.
 */
function filterEvents()
{
    const validParams = ['today-and-tomorrow', 'this-week', 'aujourdhui-et-demain', 'cette-semaine'];
    const whenParam = getParams().get('when');

    if (validParams.indexOf(whenParam) !== -1) {
        $('.tn-event-listing-view__results-heading').hide();

        const when = moment();

        switch(whenParam) {
            case 'aujourdhui-et-demain':
            case 'today-and-tomorrow':
                $('.today-and-tomorrow').addClass('active');
                when.add(1, 'days');
                break;
            case 'cette-semaine':
            case 'this-week':
                $('.this-week').addClass('active');
                when.endOf('week');
        }

        let selector = '.tn-prod-list-item__perf-anchor > .tn-prod-list-item__perf-property--datetime';
        let targetItem = '.tn-prod-list-item__perf-list-item';

        if (window.location.href.indexOf('packages') !== -1) {
            selector = '.tn-package-list-item__details > .tn-package-list-item__date-range';
            targetItem = '.tn-prod-list-item--package';
        }

        $(selector).each(function() {
            const obj = $(this);
            let date;

            if (window.location.href.indexOf('events') !== -1) {
                if (translator.to() === 'en') {
                    moment.locale('en');
                    date = moment(obj.html().match(/\w+ \d{1,2}.+ \d{4}/)[0], 'MMMM D, YYYY');
                } else {
                    moment.locale('fr');
                    date = moment(obj.html().match(/\d{1,2}\s.+\s\d{4}/), 'D MMMM YYYY');
                }
            }

            if (window.location.href.indexOf('packages') !== -1) {
                // Packages returns its date in EN so the locale needs to be updated.
                if (translator.to() === 'fr') {
                    moment.locale('fr');
                    date = moment(obj.html().match(/\d{1,2}\s.+\s\d{4}/)[0], 'D MMM YYYY');
                } else {
                    moment.locale('en');
                    date = moment(obj.html().match(/\w+ \d{1,2}.+ \d{4}/)[0], 'MMMM D, YYYY');
                }
            }

            if (date.isAfter(when)) {
                obj.closest(targetItem).remove();
            }
        });

        $('.tn-prod-list-item__property--perf-list').each(function() {
            if ($(this)[0].children.length < 1) {
                $(this)[0].closest('.tn-prod-list-item').remove();
            }
         });

        $(targetItem).addClass('active');
    } else {
        $('.default').addClass('active');
        $('.tn-prod-list-item--package, .tn-prod-list-item__perf-list-item').show();
    }
}

/**
 * Add a class to the general entry list so that we can pull it to the top using flex.
 */
function generalEntry()
{
    $(".tn-prod-list-item[data-tn-prod-season-no='16299']").addClass('general-entry');
    $(".tn-prod-list-item[data-tn-prod-season-no='23467']").addClass('general-entry-second');
}

/**
 * Return GET params.
 */
function getParams()
{
    return new URLSearchParams(window.location.search);
}

/*
 * Loop all the calendar events and remove those that have no performances.
 */
function removeNoPerformancesEvent()
{
    $('.tn-prod-list-item').each(function() {
        if ($(this).find('.tn-prod-list-item__perf-list-item').length === 0) {
            $(this).remove();
        }
    });
}

/*
 * Remove past times button nodes from the DOM.
 * It is removing then rather than hidden them to reduce the amount of translations.
 * @param {*} timesContainerSelector
 */
function removePastTimesButtons(timesContainerSelector = '.tn-ticket-selector__zone-selector')
{
    // If the node exists.
    if ($(timesContainerSelector + ' .tn-radio-button-list').length > 0) {

        // The time regex. It is the one used on the regexTranslations file.
        const timeRegex = /(\d{1,2}\:\d{2}\s(A|P)\M)/;

        const now = moment().tz('America/Toronto');

        let eventDate;
        let eventDateFormat = 'MMMM D, YYYY H:mm A';
        let eventDateLocale = 'en';
        let isPackages      = false;

        // If is an event page.
        if ($('.tn-event-detail__performance-details-container .tn-event-detail__display-time').length > 0) {
            // Extract the Event's date to use it later on the moment object.
            eventDate = $('.tn-event-detail__performance-details-container .tn-event-detail__display-time').html().trim();
        }

        // If is a package page.
        if ($('.tn-flex-performance-selector__form-group select').length > 0) {
            // Extract the Event's date from the selected date to use it later on the moment object.
            eventDate       = $('.tn-flex-performance-selector__form-group select :selected').first().text();
            eventDateFormat = 'MMM D, YYYY H:mm A';
            isPackages      = true;
        }

        // Update date format for the FR TNEW. They use different date formats.
        if (translator.to() === 'fr') {
            eventDateFormat = 'D MMMM YYYY H:mm A';

            if (isPackages) {
                eventDateLocale = 'fr';
            }
        }

        // If the date is not in the future.
        if (now.format('YYYY-MM-DD HH:mm') >= moment(eventDate + ' 00:00 AM', eventDateFormat, eventDateLocale).format('YYYY-MM-DD HH:mm')) {
            // Loop all the time nodes.
            $(timesContainerSelector + ' .tn-radio-button-list .tn-radio-button-list__list-item').each(function() {
                // If the content of the node matches the regex for time.
                if (timeRegex.test($(this).find('span').html().trim())) {
                    let regexMatch = timeRegex.exec($(this).find('span').html().trim())[1];
                    let momentObj = moment(eventDate + ' ' + regexMatch, eventDateFormat, eventDateLocale);

                    // If the time has past remove the node.
                    if (now.format('YYYY-MM-DD HH:mm') > momentObj.format('YYYY-MM-DD HH:mm')) {
                        $(this).remove();
                    }
                }
            });
        }
    }
}

/*
 * Remove past times rows for the single tickets on movies nodes from the DOM.
 * It is removing then rather than hidden them to reduce the amount of translations.
 */
function removePastTimesRows()
{
    // Check the instances node inside an event block on the event listing.
    // This node has a row shape and contain first the date (sometimes the time) and an anchor.

    // If the node containing the date and the link exists.
    if ($('.tn-prod-list-item__perf-anchor').length > 0) {
        // As the event can have more tha one instance we loop them all.
        $('.tn-prod-list-item__perf-anchor').each(function() {
            // Store the parent item to avoid closures.
            let self = $(this);

            // The instance is being divided in two lines. Dates and anchors. We focus on the dates.
            $($(self).find('.tn-prod-list-item__perf-property--datetime')).each(function() {
                // Avoid removing a row we don't want to remove.
                // We will only remove rows that have a time
                // Time is the one that we are going to check to see if has passed.
                if ($($(this).find('.tn-prod-list-item__perf-time')).length > 0) {
                    // Store date and time values for an easier code read.
                    // They are separed notes so we store them to concatenate later on.
                    let date = $($(this).find('.tn-prod-list-item__perf-date')).html();
                    let time = $($(this).find('.tn-prod-list-item__perf-time')).html();

                    // This would be the default date format and locale.
                    // Depending the section of the site or its laguange it would be updated later on.
                    let eventDateFormat = 'MMM D, YYYY HH:mm A';
                    let eventDateLocale = 'en';

                    if (translator.to() === 'fr') {
                        eventDateFormat = 'D MMMM YYYY HH:mm';
                    }

                    // We create a moment object to compare the dates and find out if it has passed.
                    let momentObj = moment(date + ' ' + time, eventDateFormat, eventDateLocale);

                    // Check if the date is valid to avoid errors with the 'Select a date' default option.
                    if (momentObj.isValid()) {
                        // If the time has passed remove the node.
                        if (moment().tz('America/Toronto').format('YYYY-MM-DD HH:mm') > momentObj.format('YYYY-MM-DD HH:mm')) {
                            $(self).parent('li').remove();
                        }
                    }
                }
            });
        });
    }

    // Check if one of the event have no performances and remove it from the list.
    removeNoPerformancesEvent();
}

/*
 * Remove past times option nodes from the DOM.
 * It is removing then rather than hidden them to reduce the amount of translations.
 */
function removePastTimesSelects()
{
    // If the node exists.
    if ($('.tn-flex-performance-selector__toggle-container .tn-flex-performance-selector__form-group').length > 0) {
        // Loop all the nodes that contains a select field with dates as their option.
        $('.tn-flex-performance-selector__toggle-container .tn-flex-performance-selector__form-group').each(function() {
            // Loop all the options for each select field.
            $($(this).find('select option')).each(function() {
                let timeRegex       = /(\d{1,2}\:\d{2})/;
                let eventDateFormat = 'MMM D, YYYY HH:mm';
                let eventDateLocale = 'en';

                if (translator.to() === 'fr') {
                    timeRegex       = /(\d{1,2}\ h \d{2})/;
                    eventDateFormat = 'D MMM YYYY HH [h] mm';
                    eventDateLocale = 'fr';
                }

                // If the content of the option field contains time and not only a date.
                if (timeRegex.test($(this).text())) {
                    let momentObj = moment($(this).text(), eventDateFormat, eventDateLocale);

                    // Check if the date is valid to avoid errors with the 'Select a date' default option.
                    if (momentObj.isValid()) {
                        // If the time has past remove the node.
                        if (moment().tz('America/Toronto').format('YYYY-MM-DD HH:mm') > momentObj.format('YYYY-MM-DD HH:mm')) {
                            $(this).remove();
                        }
                    }
                }
            });
        });
    }
}

/**
 * Loop all the price types and sort them following the structure in the arrays.
 *
 * A bought membership will be needed to see the membership prices.
 * Memberships can be bought with the fake card. Details in Passpack.
 */
function reorderPrices()
{
    // Order followed by the client depending on the language.
    let order = [
        '*Memb Adult/Adulte',
        '*Memb Senior/Aîné',
        '*Memb Tiny Tot/Tout-petit',
        '*Memb Child/Enfant',
        '*Memb Student 13+/Étudiant 13+',
        '*Memb Student 18+/Étudiant 18+',
        '*Adult/Adulte',
        '*Senior/Aîné',
        '*Tiny Tot/Tout-petit',
        '*Child/Enfant',
        '*Student 13+/Étudiant 13+',
        '*Student 18+/Étudiant 18+',
        '*Postdoctoral',
        '*Staff-Volunteer/Employe-Benev',
        '*DiscountedAdult/Rabais adulte',
        '*Memb Pkg Adult/Adulte',
        '*Memb Pkg Senior/Aîné',
        '*Memb Pkg Tiny Tot/Tout-petit',
        '*Memb Pkg Child/Enfant',
        '*Memb Pkg Student 13+/Étud 13+',
        '*Memb Pkg Stu 18+/Étud 18+',
        '*Pkg Adult/Adulte',
        '*Pkg Senior/Aîné',
        '*Pkg Tiny Tot/Tout-petit',
        '*Pkg Child/Enfant',
        '*Pkg Student 13+/Étudiant 13+',
        '*Pkg Student 18+/Étudiant 18+',
        '*Pkg2 Adult/Adulte',
        '*Pkg2 Senior/Aîné',
        '*Pkg2 Tiny Tot/Tout-petit',
        '*Pkg2 Child/Enfant',
        '*Pkg2 Student 13+/Étudiant 13+',
        '*Pkg2 Student 18+/Étudiant 18+',
        '*Comp Adult/Adulte grac.',
        '*Comp Senior/Aîné grac.',
        '*Comp TinyTot/Tout-petit grac.',
        '*Comp Child/Enfant grac.',
        '*Comp Stu 13+/Étud 13+ grac.',
        '*Comp Stu 18+/Étud 18+ grac.',
    ];

    let sortedItems = [];
    const isPackagePage = $('.tn-flex-package-detail-page').length > 0;
    let priceListSelector = 'fieldset .tn-ticket-selector__pricetype-container';

    // Push the nodes onto an array so that we can use the sort method.
    document.querySelectorAll(priceListSelector).forEach((itemsGroup) => {
        sortedItems = [];

        itemsGroup.querySelectorAll('.tn-ticket-selector__pricetype-list li').forEach((item) => {
            sortedItems.push(item)
        })

        let itemOrder = (item) => {
            if (isPackagePage) {
                return order.indexOf(
                    item.querySelector('h4').innerText.trim()
                )
            } else {
                return order.indexOf(
                    item.innerText.split('$')[0].trim()
                )
            }
        }

        // Sort the array based on the content of the node.
        sortedItems.sort((a, b) => {
            a = itemOrder(a);
            b = itemOrder(b);

            if (a == b) {
                return 0
            }

            if (a < b) {
                return -1
            }

            return 1
        })

        // Append the ordered children to the prices list.
        .forEach((item) => {
            itemsGroup.querySelector('.tn-ticket-selector__pricetype-list').appendChild(item)
        })
    })
}

/**
 * What for when the ajax is complete on a page and try and translate the Prices
 * @param {*} priceLabelTranslations
 */
function translatePackagePrices(priceLabelTranslations)
{

    $(document).ajaxStop(function(){
        setTimeout(function(){
            reorderPrices();
            translator
                .replace(priceLabelTranslations, ['.tn-ticket-selector__pricetype-description']);

            // Run any french regex needed.
            if (translator.to() === 'fr') {
                translator.regex(frenchRegexTranslations).dates(dateTranslations);
            }
        }, 500);
    });
}

/**
 * Loop all the nodes given and update it content to lower case.
 * @param {*} selectors
 */
function toLower(selectors)
{
    document.querySelectorAll(selectors).forEach(item => {
        let momentObj = moment(item.innerHTML.trim(), ['D MMM YYYY', 'MMM YYYY'], 'fr');

        if (momentObj.isValid()) {
            item.innerHTML = item.innerHTML.trim().toLowerCase();
        }
    });
}

/**
 * Updates the price format from 1,000,000.00 to 1 000 000,00.
 * @param {*} price
 */
function updatePriceStructure(price)
{
    return price.replace(/,/gm, ' ').replace(/\./gm, ',');
}

export { filterEvents, generalEntry, removePastTimesButtons, removePastTimesRows, removePastTimesSelects, reorderPrices, translatePackagePrices, toLower, updatePriceStructure };

/** @format */

import moment from 'moment';
import { updatePriceStructure } from './helpers.js';

const patternForPrice = [
    {
        // Pattern to match prices.
        // ex: $0.00 | $1,000.00
        pattern: /^\$(\d+(\,?\d*){1,}\.\d+?)$/,
        callback(match, a, b) {
            return `${updatePriceStructure(a)} $`;
        },
    }
];

const pattenrForTextAndPriceAtEnd = [
    {
        // As the result is a <span> and our price without any tag.
        // The needed pattern will be something that finish with the price structure.
        // ex: <span class="sr-only">Total for Quantity Selected: </span> {{line break and lot of spaces}} $5.00
        pattern: /\$(\d+(\,?\d*){1,}\.\d+?)$/,
        callback(match, a, b) {
            return `${updatePriceStructure(a)} $`;
        },
    },
];

const patternForTime = [
    {
        // A regex is needed for those Date Times that are surrounded by some other elements like spans.
        // ex: <span class="sr-only">Product Description: </span> {{line break and lot of spaces}} 1:15 PM
        pattern: /(\d{1,2}\:\d{2}\s(A|P)\M)/,
        callback(match, a, b) {
            let date = moment(a, 'H:mm A', 'en');
            return date.locale('fr').format('H [h] mm');
        }
    }
];

const frenchRegexTranslations = {
    // ===== Single Event =====

    // Price that appears when you select the kind of ticket for a Single Event.
    '.tn-ticket-selector__pricetype-name': pattenrForTextAndPriceAtEnd,

     // Price that appears when you select the kind of ticket for a Single Event (Mobile only).
     '.tn-flex-mobile-cart-drawer__summary-subtotal span': [
        {
            // Pattern to match any text, space and price structure.
            // ex: $15.00
            pattern: /^\$(\d+(\,?\d*\.\d+)?)$/,
            callback(match, a, b, c) {
                return `${updatePriceStructure(a)} $`;
            },
        },
    ],

    //  that appear on those Events that need to pick a Time.
    '.tn-radio-button-list__button': patternForTime,

    // ===== Packages =====

    // Sub total price on the Package items picker.
    '.tn-flex-cart__package-subtotal': patternForPrice,

    // Price displayed for the item picked after choosing it date on the Packages items picker.
    '.tn-ticket-selector__quantity-selected-price': pattenrForTextAndPriceAtEnd,

    // Price displayed for the item picked after choosing it date on the Packages items picker.
    '.tn-ticket-selector__pricetype-individual-price': patternForPrice,

    // Price displayed for each item after choosing them on the Packages items picker.
    '.tn-flex-selection__details > div': pattenrForTextAndPriceAtEnd,

    // Event Time displayed for each item after choosing them on the Packages items picker.
    '.tn-flex-selection__details div': patternForTime,

    // Price displated for choosing an item when there's more than one kind of ticket on the Packages items picker.
    '.tn-ticket-selector__zone-subtotal-amount': patternForPrice,

    // ===== Cart Details =====

    // Each element price on the Cart Details page.
    '.tn-cart-item-summary__property--price-total': pattenrForTextAndPriceAtEnd,

    // Each element price on the Cart Details page (second and smaller one).
    '.tn-cart-item-detail__list-item--price': pattenrForTextAndPriceAtEnd,

    // Both subtotal prices on the Cart Details page.
    '.tn-cart-totals__value': patternForPrice,

    // Date Time that appears on the Cart Details page for those Events that needa a specified Time.
    '.tn-cart-item-detail__list-item': patternForTime,

    '.tn-events-calendar__event-time': [
        {
            pattern: /(\d{1,2}\:\d{2})/,
            callback(match, a, b) {
                let date = moment(a, 'H:mm', 'en');
                return date.locale('fr').format('H [h] mm');
            }
        },
    ],
};

export { frenchRegexTranslations };

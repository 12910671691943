/** @format */

const hrefTranslations = {

    'Planifiez votre visite': {
        en: 'https://nature.ca/en/plan-your-visit',
        fr: 'https://nature.ca/fr/planifiez-votre-visite',
    },

    'Adhésions': {
        en: 'https://nature.ca/en/about-us/support-museum/membership/buy-membership',
        fr: 'https://nature.ca/fr/sujet-musee/appuyez-musee/devenez-membre/adherez-en-ligne',
    },

    'Cette semaine': {
        en: '?when=this-week#dynamic-links',
        fr: '?when=cette-semaine#dynamic-links',
    },

    'Aujourd\'hui et demain': {
        en: '?when=today-and-tomorrow#dynamic-links',
        fr: '?when=aujourdhui-et-demain#dynamic-links',
    },

    'Billets': {
        en: '/events?when=today-and-tomorrow#dynamic-links',
        fr: '/events?when=aujourdhui-et-demain#dynamic-links',
    },

}

export { hrefTranslations };
/** @format */

import Translator from './Translator.js';
import { stringTranslations, priceLabelTranslations } from './stringTranslations.js';
import { dateTranslations, datesOnPackages } from './dateTranslations.js';
import { onEventTranslations, OnEnventOnCalendarClicks, onEventOnPackages } from './onEventTranslations.js';
import { frenchRegexTranslations } from './regexTranslations.js';
import { filterEvents, generalEntry, removePastTimesButtons, removePastTimesRows, toLower, reorderPrices } from './helpers.js';
import { dateSelectors, datesToLowerOnCalendar } from './datesToLower.js';

const translator = new Translator();

const deferredOnPackagesWithDateRegexAndEvent = {
    // It is not using any node as it is being called twice on this file where the node is added.
    callback: (mutation, node) => {
        setTimeout(function() {
            translator
                .dates(datesOnPackages)
                .onEvent(onEventOnPackages);

            // Remove passed times from time pickers.
            removePastTimesButtons('.tn-ticket-selector__controls-container');

            // Update dates that need to be lower case and french regex.
            if (translator.to() === 'fr') {
                translator.regex(frenchRegexTranslations);
                toLower(dateSelectors);
            }
        }, 1);
    },
    mutations: { childList: true },
}

const deferredTranslations = {
// ===== Calendar =====

// Listener to wait for the first calendar load on the Events page.
    '.tn-event-listing-view__results-container': {
        callback: (mutation, node) => {
            setTimeout(function() {
                // Removed passed rows from the movie events.
                removePastTimesRows();

                translator
                    .dates(dateTranslations)
                    .onEvent(OnEnventOnCalendarClicks);

                filterEvents();
                generalEntry();

                // Update dates that need to be lower case.
                if (translator.to() === 'fr') {
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
        mutations: { childList: true },
    },

    // Calendar Month View
    '.tn-events-calendar__table-container': {
        callback: (mutation, node) => {
            setTimeout(function() {
                translator
                    .dates(dateTranslations)
                    .onEvent(onEventTranslations);

                // Run any french regex needed.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                }
            }, 1);
        },
        mutations: { childList: true},
    },

    '.tn-event-listing-view__results-heading': {
        callback: (mutation, node) => {
            setTimeout(function() {
                translator.dates(dateTranslations);

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
        mutations: { childList: true},
    },

    // ===== Packages =====

    // Listener to wait for the different items to be choosen on the Packages items picker.
    '.tn-flex-selection__list--in-production-season':
        // Translates the Price and Date and sets a click listener on the erase Event option.
        deferredOnPackagesWithDateRegexAndEvent,
}

const calendarLoad = {
    callback: (mutation, node) => {
        setTimeout(function() {
            translator.onEvent(onEventTranslations);
        }, 1);
    },
    mutations: { childlist:true }
}

const calendarViewDates = {
    // It is used on the Events Calendar view for Months and Weeks view.
    '.tn-events-calendar__table-container': {
        callback: (mutation, node) => {
            setTimeout(function() {
                translator.dates(dateTranslations);

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
        mutations: { childList: true },
    },

    // It is used on the Events Calendar view for Days view.
    '.tn-events-calendar__list-container': {
        callback: (mutation, node) => {
            setTimeout(function() {
                translator.dates(dateTranslations);

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
        mutations: { childList: true },
    },
}

const deferredOnContribute = {
    // Listener to wait until the Contribution kind have been selected on Contribute 1 and Contribute 2.
    // Created specifically for some options that have an extra info section that takes a bit more to load.
    '.tn-contribution-type-form-container': {
        callback: (mutation, node) => {
            setTimeout(function() {
                translator.string(stringTranslations, ['.tn-required-field',]);
            }, 1);
        },
        mutations: { childList: true },
    },
};

const deferredOnLogin = {
    // Listener to wait for errors on the forgot password popup.
    '#tn-account-login-forgot-password-failure-message': {
        callback: (mutation, node) => {
            setTimeout(function() {
                translator.string(stringTranslations, ['#tn-account-login-forgot-password-failure-message',]);
            }, 1);
        },
        mutations: { childList: true },
    },
}

const deferredOnPackages = {
    // Listener set for the list of dates on an event on the Packages items picker.
    '.tn-flex-performance__ticket-selector-target':
        // Depending on if the Event have more than one Date sets a listener or translates the Price and Date.
        deferredOnPackagesWithDateRegexAndEvent,

    // Listener set to see any change on the summary section on the Packages items picker.
    '.tn-flex-cart__primary-section--selections': {
        callback: (mutation, node) => {
            setTimeout(function() {
                // Translates the Price and Date and sets a click listener on the erase Event option.
                translator
                    .dates(dateTranslations)
                    .onEvent(onEventOnPackages);

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(dateSelectors);
                }
            }, 1);
        },
        mutations: { childList: true },
    },

    // Package Price Labels
    '.tn-flex-performance__container': {
        callback: (mutation, node) => {
            reorderPrices();
            setTimeout(function() {
                translator.replace(priceLabelTranslations, ['.tn-ticket-selector__pricetype-description']);
            }, 1);

            // Run any french regex needed.
            if (translator.to() === 'fr') {
                translator.regex(frenchRegexTranslations);
            }
        },
        mutations: { childList: true },
    },

}

const deferredOnRegister = {
    // Listener set for changes on the address state selector on the Register page.
    '#Address_State': {
        callback: (mutation, node) => {
            // Canadian states on french ordered alphabetically.
            let states =
            `<option value=""></option>
            <option value="AB">Alberta</option>
            <option value="BC">Colombie-Britannique</option>
            <option value="PE">Île-du-Prince-Édouard</option>
            <option value="MB">Manitoba</option>
            <option value="NB">Nouveau-Brunswick</option>
            <option value="NS">Nouvelle-Écosse</option>
            <option value="NU">Nunavut</option>
            <option value="ON">Ontario</option>
            <option value="QC">Quebec</option>
            <option value="SK">Saskatchewan</option>
            <option value="NL">Terre-Neuve-et-Labrador</option>
            <option value="NT">Territoires du Nord-Ouest</option>
            <option value="YT">Yukon</option>`;

            // If the selected Country is Canada.
            if ($('#Address_CountryId').val() === '32') {
                $('#Address_State').html(states);
            }
        },
        mutations: { childList: true },
    },
}

export { deferredTranslations, calendarLoad, calendarViewDates, deferredOnContribute, deferredOnLogin, deferredOnPackages, deferredOnRegister };

/** @format */

const ariaLabelTranslations = {
    // ===== Calendar =====

    // Calendar widget next and previous arrows.
    'Previous Year': {
        en: 'Previous Year',
        fr: 'Année précédente',
    },

    'Next Year': {
        en: 'Next Year',
        fr: 'Année suivante',
    },

    'Previous Month': {
        en: 'Previous Month',
        fr: 'Mois précédent',
    },

    'Next Month': {
        en: 'Next Month',
        fr: 'Mois suivant',
    },

    'Disabled': {
        en: 'Disabled',
        fr: 'Désactivée',
    },
};

export { ariaLabelTranslations };

/** @format */

const monthAndYear = {
    en: ['MMMM YYYY', 'MMMM, YYYY'],
    fr: ['MMMM YYYY', 'MMMM YYYY'],
}

const fullDate = {
    en: ['D MMMM YYYY', 'D MMMM YYYY HH:mm', 'MMMM D, YYYY', 'MMMM D, YYYY HH:mm'],
    fr: ['D MMMM YYYY', 'D MMM YYYY HH [h] mm', 'D MMMM YYYY', 'D MMM YYYY HH [h] mm'],
}

const fullDateShortMonth = {
    en: ['D MMM YYYY', 'MMM D, YYYY'],
    fr: ['D MMM YYYY', 'D MMM YYYY'],
}

const fullDateRangeShortMonth = {
    en: ['D MMM – D MMM YYYY', 'MMM D – MMM D, YYYY', 'D MMM YYYY', 'MMM D, YYYY'],
    fr: ['D MMM – D MMM YYYY', 'D MMM – D MMM YYYY', 'D MMM YYYY', 'D MMM YYYY'],
}

const fullDateRangeShortMonthWithTime = {
    en: ['D MMM YYYY', 'D MMM YYYY HH:mm', 'MMM D, YYYY', 'MMM D, YYYY HH:mm'],
    fr: ['D MMM YYYY', 'D MMM YYYY HH [h] mm', 'D MMM YYYY', 'D MMM YYYY HH [h] mm'],
}

const dateTranslations = {
    // ===== Calendar Month View =====

    // Calendar picker Date on Months view on the Events page.
    '#tn-events-calendar-view-month .tn-btn-datepicker__date-display': monthAndYear,

    // Month name above the calendar on Months view on the Events page.
    '#tn-events-calendar-view-month .tn-event-listing-view__results-container .tn-events-calendar__table-container h3': monthAndYear,

    // Dates on the table header on Months view on the Events page.
    '#tn-events-calendar-view-month .tn-event-listing-view__results-container .tn-events-calendar__table thead th': {
        en: ['ddd'],
        fr: ['ddd'],
    },

    // ===== Calendar Week View =====

    // Calendar picker Date on Weeks view on the Events page.
    '#tn-events-calendar-view-week .tn-btn-datepicker__date-display': fullDateRangeShortMonth,

    // Date range above the calendar on Weeks view on the Events page.
    '#tn-events-calendar-view-week .tn-event-listing-view__results-container .tn-events-calendar__table-container h3': fullDateRangeShortMonth,

    // Dates on the table header on Weeks view on the Events page.
    '#tn-events-calendar-view-week .tn-event-listing-view__results-container .tn-events-calendar__table thead th': {
        en: ['D MMM', 'MMM, D', 'dddd MMM D'],
        fr: ['D MMM', 'D MMM'],
    },

    // ===== Calendar Day View =====

    // Calendar picker Date on Days view on the Events page.
    '#tn-events-calendar-view-day .tn-btn-datepicker__date-display': fullDateShortMonth,

    // Day date above the calendar on Days view on the Events page.
    '#tn-events-calendar-view-day .tn-event-listing-view__results-container .tn-events-calendar__list-container h3': fullDateShortMonth,

    // Day name on the table header on Days view on the Events page.
    '#tn-events-calendar-view-day .tn-event-listing-view__results-container .tn-events-calendar__list-container li h4': {
        en: ['dddd'],
        fr: ['dddd'],
    },

    // ===== Calendar List View =====

    // Calendar pickers Date on List view on the Events page.
    '#tn-events-list-view .tn-btn-datepicker__date-display': fullDateShortMonth,

    // Monthly Date range above the events on List view on the Events page.
    '#tn-events-list-view .tn-event-listing-view__results-heading': fullDateRangeShortMonth,

    // Each performance Date on List view on the Events page.
    '#tn-events-list-view .tn-prod-list-item__perf-date': fullDate,

    // Each performance Time on List view on the Events page.
    '#tn-events-list-view .tn-prod-list-item__perf-time': {
        en: ['HH:mm'],
        fr: ['HH [h] mm'],
    },

    // ===== Calendar Picker =====

    // Dates on Date selector.
    '.calendar .month span': {
        en: ['MMMM'],
        fr: ['MMMM'],
    },

    // ===== Single Event =====

    // Date that appears just before the price on Single Event.
    '.tn-event-detail__display-time': fullDate,

    // Single events with a Date dropdown selector on the left like cinemas.
    '#tn-additional-events-select option': {
        en: ['D MMMM YYYY', 'D MMMM YYYY HH:mm', 'MMMM D, YYYY', 'MMMM D, YYYY HH:mm'],
        fr: ['D MMMM YYYY', 'D MMMM YYYY HH [h] mm', 'D MMMM YYYY', 'D MMMM YYYY HH [h] mm'],
    },

    // ===== Cart =====

    // Each individual item Date on the Cart.
    '.tn-cart-item-summary__property--date-time': fullDate,

    // ===== Receipt =====

    // Date displaying the time the receipt have been created at the end of the Cart process.
    '.tn-order-date': {
        en: ['[Date de la commande :\n] D MMMM YYYY HH:mm'],
        fr: ['[Date de la commande :\n] D MMMM YYYY HH [h] mm'],
    },

    // ===== Packages =====

    // Each individual event Date on the Packages listing page.
    '.tn-package-list-item__date-range': fullDateShortMonth,

    // Package Date once you've picked one Package from it listing page.
    '.tn-flex-package-details__date-range': fullDateShortMonth,

    // Each item Date once you've picked them on the Packages items picker.
    '.tn-flex-selection__details div:nth-child(2)': fullDate,

    // Package Selection dropdowns
    '.tn-flex-performance-selector__select option': fullDate,

    // ===== Overview =====

    // Date that appear on each peformance on the Overview's page listing section.
    '.tn-prod-list-item__perf-date': fullDate,

    // Additional events section after picking a Date on the Overview's page listing section.
    '.tn-additional-events__list-item a': fullDate,

};

const datesOnPackages = {
    // Dates on the selector of each package item.
    '.tn-flex-performance-selector__form-group select option':
        // Depending on the package selected the date can or cannot contain time.
        // ex: 11 April 2019 | 13 April 2019 11:15
        fullDateRangeShortMonthWithTime,
};

export { dateTranslations, datesOnPackages };

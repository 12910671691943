/** @format */

import Translator from './Translator.js';
import { ariaLabelTranslations } from './ariaLabelTranslations.js';
import { dateTranslations, datesOnPackages } from './dateTranslations.js';
import { stringTranslations, stringOnCalendar, priceLabelTranslations } from './stringTranslations.js';
import { deferredTranslations, calendarViewDates, deferredOnContribute, deferredOnLogin, deferredOnPackages, deferredOnRegister, deferreredEventMessage } from './deferredTranslations.js';
import { frenchRegexTranslations } from './regexTranslations.js';
import { filterEvents, generalEntry, removePastTimesRows, removePastTimesSelects, toLower }   from './helpers.js';
import { dateSelectors, datesToLowerOnCalendar } from './datesToLower.js';

const translator = new Translator();

const packagesDeferredOnClick = {
    // It is not using any node as it is being called twice on this file where the node is added.
    on: 'click',
    callback: (node, event) => {
        setTimeout(function() {
            translator.deferred(deferredOnPackages);
        }, 1);
    },
}

const onEventTranslations = {

    // ===== Calendar =====

    '#tn-event-listing-mode-nav-calendar-view': {
        on: 'click',
        callback: () => {
            setTimeout(function() {
                translator.dates(dateTranslations);

                // Run any french regex needed.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                }
            }, 1);
        },
    },

    // Trigger for clicking on the Calendar date tabs.
    '#tn-events-calendar-mode-tab-nav': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator
                    .dates(dateTranslations)
                    .deferred(calendarViewDates);

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
    },

    // Click on the previous or next arrows on the Date picker.
    '.tn-btn-datepicker__btn-period-prev-next': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.deferred(calendarViewDates);

                // Update dates that need to be lower case.
                if (translator.to() === 'fr') {
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
    },

    // List view - Cal dropdown first click
    // Targets days and month
    '.tn-events-list-view__datepicker-container': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator
                    .dates(dateTranslations)
                    .ariaLabel(ariaLabelTranslations, ['.month', '.year', '.day'])
                    .onEvent(OnEnventOnCalendarClicks);

                let nodes = document.querySelectorAll('.calendar th.week');

                if (nodes) {
                    nodes.forEach((item, i) => {
                        item.innerHTML = stringOnCalendar[i][translator.to()];
                    });
                }
            }, 1);
        },
    },

    // Calendar view - Cal dropdown first click
    // Targets days and month
    '.tn-btn-datepicker__btn-container': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator
                    .dates(dateTranslations)
                    .ariaLabel(ariaLabelTranslations, ['.month', '.year', '.day'])
                    .onEvent(OnEnventOnCalendarClicks);

                let nodes = document.querySelectorAll('.calendar th.week');

                if (nodes) {
                    nodes.forEach((item, i) => {
                        item.innerHTML = stringOnCalendar[i][translator.to()];
                    });
                }
            }, 1);
        },
    },

    // Trigger for clicking on the Show/Hide button
    '.hide-info': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.string(stringTranslations, ['.hide-info',]);
            }, 1);
        },
    },

    // Listener set to see when modal is opened.
    '.tn-flex-production__btn-more-info': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.string(stringTranslations, ['.modal-btn-confirm']);
            }, 1);
        },
    },

    // Trigger for clicking on the Calendar list view tab.
    '#tn-event-listing-mode-nav-list-view': {
        on: 'click',
        callback: (node, event) => {
            translator.dates(dateTranslations);

            filterEvents();
            generalEntry();

            // Update dates that need to be lower case.
            if (translator.to() === 'fr') {
                toLower(datesToLowerOnCalendar);
            }
        },
    },

    // ===== Packages =====

    // Trigger for removing a choosen event on the Packages items picker.
    '.tn-flex-selection__btn-remove': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator
                    .dates(datesOnPackages)
                    .deferred(deferredOnPackages);

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(dateSelectors);
                }
            }, 1);
        },
    },

    // ===== Contribute =====

    // Trigger for selecting the kind of contribution you want to do on either Contribute 1 and Contribute 2.
    '#AddContribution_ContributionTypeId': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.deferred(deferredOnContribute);
            }, 1);
        },
    },

    // ===== Login =====

    // Trigger for selecting a country on the Register page.
    '#tn-account-login-forgot-login-link': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.onEvent(onEventOnLogin);
            }, 1000);
        },
    },

    // Trigger for clicking on submit forgot password form.
    // This trigger is supposed to happen only if you trigger the modal from the URL:
    // https://test-na-cnat.tn.services/account/login?forgot=true
    '.modal-btn-confirm': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.deferred(deferredOnLogin);
            }, 1);
        },
    },

    // ===== Register =====

    // Trigger for selecting a country on the Register page.
    '#Address_CountryId': {
        on: 'change',
        callback: (node, event) => {
            setTimeout(function() {
                translator.deferred(deferredOnRegister);
            }, 1);
        },
    },
};

const OnEnventOnCalendarClicks = {
    // Trigger for translations on the Events List when picking a day on the date picker.
    '.tn-events-list-view__datepicker-container .day': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                // Removed passed rows from the movie events.
                removePastTimesRows();

                translator
                    .dates(dateTranslations)
                    .deferred(deferredTranslations);

                filterEvents();
                generalEntry();

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
    },

    // Trigger for translations on the Events Calendar when picking a day on the date picker.
    '.tn-btn-datepicker__btn-container .day': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator
                    .dates(dateTranslations)
                    .deferred(deferredTranslations);

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
    },

    // Trigger for translations on the Events Calendar when click the restart dates button.
    // This translation will happen only if the calendar picker loaded a new bunch of events.
    // As the restart dates button will load the initial events again.
    '.tn-events-list-view__btn-reset-dates': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.dates(dateTranslations);

                filterEvents();
                generalEntry();

                // Update dates that need to be lower case and french regex.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                    toLower(datesToLowerOnCalendar);
                }
            }, 1);
        },
    },
}

const onEventOnLogin = {
    // Trigger for clicking on submit forgot password form.
    '.modal-btn-confirm': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.deferred(deferredOnLogin);
            }, 1);
        },
    },
}

const onEventOnPackages = {
    // Trigger for clicking on an event Date on the Packages items picker.
    '.tn-radio-button-list__list-item': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                translator.onEvent(onEventOnPackages);

                // Run any french regex needed.
                if (translator.to() === 'fr') {
                    translator.regex(frenchRegexTranslations);
                }
            }, 1);
        },
    },

    // Trigger for removing a choosen event on the Packages items picker.
    '.tn-flex-selection__btn-remove': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                // Translates the Price and Date and sets a listener that waits for changes on the summary of the Packages listing page.
                translator
                    .dates(datesOnPackages)
                    .deferred(deferredOnPackages);

                    // Run any french regex needed.
                    if (translator.to() === 'fr') {
                        translator.regex(frenchRegexTranslations);
                    }
            }, 1);
        },
    },

    '.btn-lg tn-btn-datepicker__btn': {
        on: 'click',
        callback: (node, event) => {
            setTimeout(function() {
                // Translates the Price and Date and sets a listener that waits for changes on the summary of the Packages listing page.
                translator.deferred(deferredOnPackages);
            }, 1);
        },
    },

    // Trigger for choosing an event on certain Date on the Packages items picker.
    '#tn-add-to-cart-button': packagesDeferredOnClick,

    // Trigger for choosing a cinema event Date on the Packages items picker.
    '.tn-flex-performance-selector__select': packagesDeferredOnClick,
}

export { onEventTranslations, OnEnventOnCalendarClicks, onEventOnPackages };

/** @format */

const dateSelectors = [
    // ===== Calendar Picker =====

    // Dates on Date selector.
    '.calendar .month span',

    // ===== Single Event =====

    // Date that appears just before the price on Single Event.
    '.tn-event-detail__display-time',

    // Single events with a Date dropdown selector on the left like cinemas.
    '#tn-additional-events-select option',

    // ===== Cart =====

    // Each individual item Date on the Cart.
    '.tn-cart-item-summary__property--date-time',

    // ===== Receipt =====

    // Date displaying the time the receipt have been created at the end of the Cart process.
    '.tn-order-date',

    // ===== Packages =====

    // Each individual event Date on the Packages listing page.
    '.tn-package-list-item__date-range',

    // Package Date once you've picked one Package from it listing page.
    '.tn-flex-package-details__date-range',

    // Each item Date once you've picked them on the Packages items picker.
    '.tn-flex-selection__details div:nth-child(2)',

    // Dates on the selector of each package item.
    '.tn-flex-performance-selector__form-group select option',

    // ===== Overview =====

    // Date that appear on each peformance on the Overview's page listing section.
    '.tn-prod-list-item__perf-date',

    // Additional events section after picking a Date on the Overview's page listing section.
    '.tn-additional-events__list-item',
];

const datesToLowerOnCalendar = [
    // ===== Calendar Day View =====

    // Calendar picker Date on Days view on the Events page.
    '#tn-events-calendar-view-day .tn-btn-datepicker__date-display',

    // Day date above the calendar on Days view on the Events page.
    '#tn-events-calendar-view-day .tn-event-listing-view__results-container .tn-events-calendar__list-container h3',

    // ===== Calendar Week View =====

    // Calendar picker Date on Weeks view on the Events page.
    '#tn-events-calendar-view-week .tn-btn-datepicker__date-display',

    // Date range above the calendar on Weeks view on the Events page.
    '#tn-events-calendar-view-week .tn-event-listing-view__results-container .tn-events-calendar__table-container h3',

    // Dates on the table header on Weeks view on the Events page.
    '#tn-events-calendar-view-week .tn-event-listing-view__results-container .tn-events-calendar__table thead th',

    // ===== Calendar List View =====

    // Calendar pickers Date on List view on the Events page.
    '#tn-events-list-view .tn-btn-datepicker__date-display',

    // Monthly Date range above the events on List view on the Events page.
    '#tn-events-list-view .tn-event-listing-view__results-heading',

    // Each performance Date on List view on the Events page.
    '#tn-events-list-view .tn-prod-list-item__perf-date',

    // Each performance Time on List view on the Events page.
    '#tn-events-list-view .tn-prod-list-item__perf-time',
];

export { dateSelectors, datesToLowerOnCalendar };

/** @format */

const stringTranslations = {
    // ===== Calendar =====

    // Calendar tab for kind of view.
    'View as Calendar': {
        en: 'View as Calendar',
        fr: 'Visualiser sous forme de calendrier',
    },

    // Calendar tab for kind of view.
    'View as List': {
        en: 'View as List',
        fr: 'Visualiser sous forme de liste',
    },

    // Calendar tab kind of date display.
    Day: {
        en: 'Day',
        fr: 'Jour',
    },

    // Calendar tab kind of date display.
    Week: {
        en: 'Week',
        fr: 'Semaine',
    },

    // Calendar tab kind of date display.
    Month: {
        en: 'Month',
        fr: 'Mois',
    },

    // ===== Register =====

    // Creating an account: Prefix - (None).
    '(None)': {
        en: '(None)',
        fr: '(Aucun)',
    },

    'The email address you submitted was invalid. Please try again.': {
        en: 'The email address you submitted was invalid. Please try again.',
        fr: 'L\'adresse courriel que vous avez soumise était invalide. Veuillez réessayer.',
    },

    // ===== Account =====

    // Selec preferences: 'Your changes have been saved'
    'Your changes have been saved': {
        en: 'Your changes have been saved.',
        fr: 'Vos modifications ont été sauvegardées.',
    },

    // ===== Receipt =====
    'Print Your Receipt': {
        en: 'Print Your Receipt',
        fr: 'Imprimez votre reçu',
    },

    // ===== General =====

    // Any Required text.
    Required: {
        en: 'Required',
        fr: 'Obligatoire',
    },

    'Please Wait': {
        en: 'Please Wait',
        fr: 'Patientez svp',
    },

    'An unexpected error has occurred.': {
        en: 'An unexpected error has occurred.',
        fr: 'Une erreur inattendue est survenue.',
    },

    // Modal close button.
    Close: {
        en: 'Close',
        fr: 'Fermer',
    },

    'Hide instructions': {
        en: 'Show instructions',
        fr: 'Montrer les instructions',
    },

    'Show instructions': {
        en: 'Hide instructions',
        fr: 'Cacher les instructions',
    },

    'Cacher les instructions': {
        en: 'Show instructions',
        fr: 'Montrer les instructions',
    },

    'Montrer les instructions': {
        en: 'Hide instructions',
        fr: 'Cacher les instructions',
    },

    // ===== Dynamic buttons =====

    'Today and tomorrow': {
        en: 'Today and tomorrow',
        fr: 'Aujourd\'hui et demain',
    },

    'This week': {
        en: 'This week',
        fr: 'Cette semaine',
    },

    'All options': {
        en: 'All options',
        fr: 'Toutes les options',
    },

    'Next 30 days': {
        en: 'Next 30 days',
        fr: '30 prochains jours',
    },

    // ===== Tickets for title =====

    'Tickets for:': {
        en: 'Tickets for:',
        fr: 'Billets pour:',
    },

};

const stringOnCalendar = {
    // Translations for the calendar picker letter that says the day of the week.
    0: {
        en: 'S',
        fr: 'D',
    },

    1: {
        en: 'M',
        fr: 'L',
    },

    2: {
        en: 'T',
        fr: 'M',
    },

    3: {
        en: 'W',
        fr: 'M',
    },

    4: {
        en: 'T',
        fr: 'J',
    },

    5: {
        en: 'F',
        fr: 'V',
    },

    6: {
        en: 'S',
        fr: 'S',
    },
}

const priceLabelTranslations = {
    // Translations for the Price labels
    '*Memb Adult/Adulte': {
        en: 'Member Adult',
        fr: 'Membre adulte',
    },
    '*Memb Senior/Aîné': {
        en: 'Member Senior (65+)',
        fr: 'Membre aîné (65+)',
    },
    '*Memb Tiny Tot/Tout-petit': {
        en: 'Member Tiny Tot (0–2)',
        fr: 'Membre tout-petit (0 à 2)',
    },
    '*Memb Child/Enfant': {
        en: 'Member Child (3–12)',
        fr: 'Membre enfant (3 à 12)',
    },
    '*Memb Student 13+/Étudiant 13+': {
        en: 'Member Student (13+)',
        fr: 'Membre étudiant (13+)',
    },
    '*Memb Student 18+/Étudiant 18+': {
        en: 'Member Student (18+)',
        fr: 'Membre étudiant (18+)',
    },
    '*Adult/Adulte': {
        en: 'Adult',
        fr: 'Adulte',
    },
    '*Senior/Aîné': {
        en: 'Senior (65+)',
        fr: 'Aîné (65+)',
    },
    '*Tiny Tot/Tout-petit': {
        en: 'Tiny Tot (0–2)',
        fr: 'Tout-petit (0 à 2)',
    },
    '*Child/Enfant': {
        en: 'Child (3–12)',
        fr: 'Enfant (3 à 12)',
    },
    '*Student 13+/Étudiant 13+': {
        en: 'Student (13+)',
        fr: 'Étudiant (13+)',
    },
    '*Student 18+/Étudiant 18+': {
        en: 'Student (18+)',
        fr: 'Étudiant (18+)',
    },
    '*Postdoctoral': {
        en: 'Post-Doc',
        fr: 'Universitaire postdoctoral',
    },
    '*Staff-Volunteer/Employe-Benev': {
        en: 'Staff/Volunteer',
        fr: 'Employé/Bénévole',
    },
    '*DiscountedAdult/Rabais adulte': {
        en: 'Discounted Adult',
        fr: 'Rabais adulte',
    },
    '*Memb Pkg Adult/Adulte': {
        en: 'Member Adult',
        fr: 'Membre adulte',
    },
    '*Memb Pkg Senior/Aîné': {
        en: 'Member Senior (65+)',
        fr: 'Membre aîné (65+)',
    },
    '*Memb Pkg Tiny Tot/Tout-petit': {
        en: 'Member Tiny Tot (0–2)',
        fr: 'Membre tout-petit (0 à 2)',
    },
    '*Memb Pkg Child/Enfant': {
        en: 'Member Child (3–12)',
        fr: 'Membre enfant (3 à 12)',
    },
    '*Memb Pkg Student 13+/Étud 13+': {
        en: 'Member Student (13+)',
        fr: 'Membre étudiant (13+)',
    },
    '*Memb Pkg Stu 18+/Étud 18+': {
        en: 'Member Student (18+)',
        fr: 'Membre étudiant (18+)',
    },
    '*Pkg Adult/Adulte': {
        en: 'Adult',
        fr: 'Adulte',
    },
    '*Pkg Senior/Aîné': {
        en: 'Senior (65+)',
        fr: 'Aîné (65+)',
    },
    '*Pkg Tiny Tot/Tout-petit': {
        en: 'Tiny Tot (0–2)',
        fr: 'Tout-petit (0 à 2)',
    },
    '*Pkg Child/Enfant': {
        en: 'Child (3–12)',
        fr: 'Enfant (3 à 12)',
    },
    '*Pkg Student 13+/Étudiant 13+': {
        en: 'Student (13+)',
        fr: 'Étudiant (13+)',
    },
    '*Pkg Student 18+/Étudiant 18+': {
        en: 'Student (18+)',
        fr: 'Étudiant (18+)',
    },
    '*Pkg2 Adult/Adulte': {
        en: 'Adult',
        fr: 'Adulte',
    },
    '*Pkg2 Senior/Aîné': {
        en: 'Senior (65+)',
        fr: 'Aîné (65+)',
    },
    '*Pkg2 Tiny Tot/Tout-petit': {
        en: 'Tiny Tot (0–2)',
        fr: 'Tout-petit (0 à 2)',
    },
    '*Pkg2 Child/Enfant': {
        en: 'Child (3–12)',
        fr: 'Enfant (3 à 12)',
    },
    '*Pkg2 Student 13+/Étudiant 13+': {
        en: 'Student (13+)',
        fr: 'Étudiant (13+)',
    },
    '*Pkg2 Student 18+/Étudiant 18+': {
        en: 'Student (18+)',
        fr: 'Étudiant (18+)',
    },
    '*Comp Adult/Adulte grac.': {
        en: 'Complimentary Adult',
        fr: 'Gratuit adulte',
    },
    '*Comp Senior/Aîné grac.': {
        en: 'Complimentary Senior (65+)',
        fr: 'Gratuit aîné (65+)',
    },
    '*Comp TinyTot/Tout-petit grac.': {
        en: 'Complimentary Tiny Tot (0–2)',
        fr: 'Gratuit tout-petit (0 à 2)',
    },
    '*Comp Child/Enfant grac.': {
        en: 'Complimentary Child (3–12)',
        fr: 'Gratuit enfant (3 à 12)',
    },
    '*Comp Stu 13+/Étud 13+ grac.': {
        en: 'Complimentary Student (13+)',
        fr: 'Gratuit étudiant (13+)',
    },
    '*Comp Stu 18+/Étud 18+ grac.': {
        en: 'Complimentary Student (18+)',
        fr: 'Gratuit étudiant (18+)',
    },
}

export { stringTranslations, stringOnCalendar, priceLabelTranslations };

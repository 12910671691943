/** @format */

export default {
    // ===== Register | Account =====

    // A couple of translations that appear in different steps on the Register and Account page.
    '.tn-interests .control-label': {},
    '.tn-interests .checkbox label': {},

    // ===== Calendar =====

    // Filters above the calendar on the Events page.
    '#tn-events-keyword-filter li span': {},
};
